<template>
    <div class="backmain">
        <div>
            <img src="../images/detailback.jpg" alt="" class="bannerimg">
        </div>
        <div class="content">
            <div class="postname">
                硬件工程师
            </div>
            <div class="titlename">
                招聘岗位：硬件工程师
            </div>
            <div class="titlename">
                【职位描述】<br>
                1、电子元器件选型，电子原理图绘制及PCB布板； <br>
                2、样品板的焊接调试及客户现场返回的问题进行定位分析；<br>
                3、协助软件工程师联调程序并给出相应的建议；<br>
                4、解决相应的硬件问题及调试问题。<br>
            </div>
            <div class="titlename" style="margin-top: 40px;">
                【职位要求】<br>
                1、本科及以上学历，电子类或通信类专业，英语四级及以上，1年以上仪表、终端等硬件开发经验，；或3年以上其它行业硬件开发经验；有低功耗设计经验优先；<br>
                2、熟悉数字、模拟电路设计、高速信号、电子元器件测试；<br>
                3、可独立进线硬件原理图电路设计，能独立进行2层、4层板PCB Layout；<br>
                4、熟悉常用电子原件关键参数，了解电磁兼容，熟练使用Altium Designer/Cadence等工具；<br>
                5、熟悉ST系列、兆易创新系列、NXP系列及单片机外围器件；<br>
                6、可熟练使用硬件测试设备，可独立排查、定位、修复硬件相关产品问题<br>
                7、熟悉终端类产品形式规范等，有电力相关产品设计经验优先。<br>
            </div>
            <hr style="color: red;">
            <div class="bottomline">
                <div class="posttexttype">
                    上一个：无
                </div>
                <div class="posttexttype" style="cursor: pointer;" @click="nextperson">
                    下一个：嵌入式开发工程师
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .bannerimg {
        width: 100%;
    }

    .backmain {
        width: 100%;
        background: white;
    }

    .content {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 30px;
        background: white;
    }

    .postname {
        font-size: 36px;
        text-align: center;
        margin-bottom: 30px;
    }

    .titlename {
        font-size: 14px;
        line-height: 30px;
    }

    .posttexttype {
        font-size: 14px;
        color: #999;
    }

    .bottomline {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
</style>

<script>
    export default {
        data() {
            return {

            };
        },
        methods: {
            nextperson() {
                window.location.href = "/detailperson1";
            },
        }
    };

</script>