<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


  // import HelloWorld from "./components/HelloWorld.vue";
  // import Home from "./components/Home.vue";
  export default {
    name: "App",

  };
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  body {
    margin: 0;
    background: #f5f5f5;
  }

  a {
    text-decoration: none;
    color: #2c3e50;
  }
</style>