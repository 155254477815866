<template>
    <div class="backmain">
        <div>
            <img src="../images/call.jpg" alt="" class="bannerimg">
        </div>
        <div class="content">

        </div>
    </div>
</template>

<style scoped>
    .bannerimg {
        width: 100%;
    }

    .backmain {
        width: 100%;
        background: white;
    }

    .content {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 30px;
        background: white;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: ''
            }
        }
    }
</script>