<template>
    <div class="backmain">
        <div>
            <img src="../images/call.jpg" alt="" class="bannerimg">
        </div>
        <div class="content">
            <div class="titletext">
                <div class="entext">CONTACT&nbsp;&nbsp;US</div>
                联系我们
            </div>
            <el-row>
                <el-col :span="8">
                    <div class="leftmain">
                        <div>
                            Call Us
                        </div>
                        <div class="leftline1">
                            合作咨询联系方式
                        </div>
                        <div class="leftlinemain">
                            <div class="leftimg">
                                <img src="../images/callus1.png" alt="">
                            </div>
                            <div>
                                <div class="bold">
                                    0571-8877-3367
                                </div>
                                <div class="lefttext">
                                    请随时联系我们！
                                </div>
                            </div>
                        </div>

                        <div class="leftlinemain">
                            <div class="leftimg">
                                <img src="../images/callus2.png" alt="">
                            </div>
                            <div>
                                <div class="bold">
                                    Email Address:
                                </div>
                                <div class="lefttext">
                                    hjny210610@163.com
                                </div>
                            </div>
                        </div>

                        <div class="leftlinemain">
                            <div class="leftimg">
                                <img src="../images/callus3.png" alt="">
                            </div>
                            <div>
                                <div class="bold">
                                    公司总部
                                </div>
                                <div class="lefttext">
                                    浙江省杭州市西湖区世贸丽晶城欧美中心（天目山路）C区1305室
                                </div>
                            </div>
                        </div>


                    </div>
                </el-col>
                <el-col :span="1">
                    &nbsp;
                </el-col>
                <el-col :span="12">
                    <div>
                        <el-input type="textarea" :autosize="{ minRows:4, maxRows: 8}" placeholder="请输入内容"
                            v-model="textarea2" class="rightinput">
                        </el-input>
                    </div>
                    <div class="rightline">
                        <el-input v-model="input1" placeholder="请输入联系人" class="rightinput"></el-input>
                    </div>
                    <div class="rightline">
                        <el-input v-model="input2" placeholder="请输入手机" class="rightinput"></el-input>
                    </div>
                    <div>
                        <div class="submitbtn">
                            提交
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<style scoped>
    .titletext {
        font-size: 36px;
        color: #000;
        margin-bottom: 60px;
    }

    .leftmain {
        border: 1px solid #ddd;
        padding: 20px;
    }

    .entext {
        font-size: 14px;
        font-weight: normal;
    }

    .bannerimg {
        width: 100%;
    }

    .backmain {
        width: 100%;
        background: white;
    }

    .content {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        padding-bottom: 30px;
        background: white;
    }

    .aboutimg {
        transition: all 1s;
        width: 100%;
        /* cursor: pointer; */
    }

    .aboutmain {
        padding-left: 10%;
    }

    .aboutmains {
        padding-right: 10%;
    }

    .aboutconttitle {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .aboutconttext {
        line-height: 30px;
        font-size: 14px;
        color: #999;
    }

    ::v-deep .el-container {
        background: white !important;
    }

    .leftline1 {
        font-size: 26px;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .leftlinemain {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(222, 226, 230, 1);
        min-height: 60px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .leftimg {
        margin-right: 20px;
    }

    .bold {
        font-weight: bold;
    }

    .lefttext {
        font-size: 14px;
        line-height: 30px;
    }

    .rightline {
        margin-top: 30px;
        width: 80%;
    }


    /* .el-textarea__inner {
        background-color: #e8eaec !important;
    } */

    .submitbtn {
        background-color: #3e5c38;
        border-color: #3e5c38;
        color: white;
        border-radius: 5px;
        cursor: pointer;
        width: 178px;
        height: 48px;
        text-align: center;
        line-height: 48px;
        margin-top: 30px;
    }
</style>

<script>
    export default {
        data() {
            return {
                input1: '',
                input2: "",
                textarea2: ''
            }
        }
    }
</script>