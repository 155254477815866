import VueRouter from 'vue-router'

import Unit from '../components/unit'
import Layout from '../components/Layout'
import About from '../components/aboutus'
import Callus from '../components/callus'
import Personrecruit from '../components/personrecruit'
import Detailperson from '../components/detailperson'
import Detailpersons from '../components/detailperson1'
import News from '../components/news'
import Product from '../components/product'


export default new VueRouter({
  mode: 'history',
  linkActiveClass: "active",
  routes: [
    {
      path: '',
      component: Layout,
      redirect: '/',
      hidden: true,
      children: [
        {
          path: '/',
          component: Unit
        },
        {
          path: '/unit',
          name: 'Unit',
          component: Unit
        },
        {
          path: '/about',
          name: 'About',
          component: About
        },
        {
          path: '/callus',
          name: 'Callus',
          component: Callus
        },
        {
          path: '/personrecruit',
          name: 'Personrecruit',
          component: Personrecruit
        },
        {
          path: '/detailperson',
          name: 'Detailperson',
          component: Detailperson
        },
        {
          path: '/detailperson1',
          name: 'Detailpersons',
          component: Detailpersons
        },
        {
          path: '/news',
          component: News
        },
        {
          path: '/product',
          component: Product
        }
        
      ]
    }
  ]
})
